@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-y: scroll;
  background-color: #f7f7f7 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1{
  font-family: 'Poppins', sans-serif;
}

h4 {
  margin: 1em 0 .6em !important;
  padding-bottom: .6em;
  border-bottom: 1px dashed #aaa;
  color: #ffb900;
  font-family: 'Poppins', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.backgroundHeader{
  background-position: 0 -40rem;
  margin-bottom: 2rem;
  background-color: #acacac;
}

.navHeadline{
  color: white;
}



.navbar {
  font-family: 'Poppins', sans-serif;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar-light .navbar-nav .nav-link .active {
  color: black !important;
}

.App-link {
  color: #61dafb;
}

/*.imageslider-height {*/
/*  max-height: 70vh;*/
/*}*/
.image-gallery-slide img {
  max-height: none !important;
  height: 30vh !important;
}
.fullscreen .image-gallery-slide img {
  max-height: calc(100vh - 80px) !important;
  height: auto !important;
}
.react-calendar__month-view__days__day--weekend.vacantCalendarTile {
  color: #930000;
}
.react-calendar__month-view__days__day--weekend.vacantCalendarTile.react-calendar__tile--active{
  color:white;
}

.react-calendar__tile.react-calendar__tile--now {
  background: #ffb900 !important;
  border-radius: 5px 5px 5px 5px;
}

.react-calendar__tile.react-calendar__tile--now.occupied:hover {
  background-color: #e6e6e6;
}

.nav-tabs .nav-item .nav-link.active{
  color: #ffb900;
  background-color: #f7f7f7;
  border-color: #dee2e6 #dee2e6 #f7f7f7;
}

.iconText.noUni {
  /*line-height: 50px;*/
  font-size: 1.3rem;
}

.table > :not(caption) > * > * {
  padding: 0.2rem 0.5rem !important;
}

.iconText {
  text-align: center;
  line-height: 3rem;
  font-size: 1.5rem;
}

.iconImage {
  height: 3rem;
}

.nav-tabs .nav-link{
  color: #6c6c6c;
}

.nav-tabs .nav-link:hover{
  color: #414141;
}

.nav-tabs {
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
}

.react-calendar__tile.occupiedStart.occupied {
  border-radius: 8px 0px 0px 8px;
}

.react-calendar__tile.react-calendar__tile--active.occupied{
  background-color: #006edc;
}
.footer {
  border-top: 1px #e3e3e3 solid;
}

.footer a{
  color: #787878;
}
.legende {
  padding-top: 1rem;
  line-height: 20px;
  font-size: 1.1rem;
  vertical-align: middle !important;
}

.legende .box {
  padding-bottom: 0.5rem;
}
.legende .box:before {
  content:"";
  width:2rem;
  height:2rem;
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle !important;
  border: 1px solid #8b8b8b;
}

.legende .occupied:before {
  background-color: #818181;
}
.legende .vacant:before {
  background-color: white;
}
.legende .today:before {
  background-color: #ffb900;
}

.react-calendar__tile.occupiedEnd.occupied {
  border-radius: 0px 8px 8px 0px;
}

.react-calendar__tile.occupied {
  border-radius: 0px 0px 0px 0px;
  background-color: #818181;
  color: white;
}

.react-calendar__tile:enabled:hover {
  color: black !important;
}

/*.vacantCalendarTile {*/
/* color: black;*/
/*}*/

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

